import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from '../../utils/iconLibrary';

type FontAwesomeIconKeys = keyof typeof oIconLibrary.fontAwesome;

type WfuIconKeys = keyof typeof oIconLibrary.wfu;

export type IconOnlyButtonProps = {
  sIcon: FontAwesomeIconKeys | WfuIconKeys;
  fnHandleClick: () => void;
  sAriaLabel: string;
  className?: string;
  sFlavor?: 'primary' | 'secondary' | 'tertiary';
  sIconType?: 'fontAwesome' | 'wfu';
  oRef?: React.RefObject<HTMLButtonElement> | null;
  sCypressId?: string;
};

/**
 * Displays an icon-only button using a FontAwesome icon.
 *
 * @param {string} sIcon - FontAwesome icon name.
 * @param {function} fnHandleClick - Click handler.
 * @param {string} sAriaLabel - Describes button action for screen readers.
 * @param {string} sIconType - Determines if the icon is from FontAwesome or a custom icon.
 */
const IconOnlyButton: React.FC<IconOnlyButtonProps> = ({
  sIcon,
  fnHandleClick,
  sAriaLabel,
  className = '',
  sFlavor = 'primary',
  sIconType = 'fontAwesome',
  oRef = null,
  sCypressId = '',
}) => (
  <button
    type='button'
    className={`button--iconOnly--${sFlavor} ${className}`}
    onClick={fnHandleClick}
    aria-label={sAriaLabel}
    ref={oRef}
    data-cy={sCypressId}
  >
    {sIconType === 'fontAwesome' ? (
      <FontAwesomeIcon
        icon={oIconLibrary[sIconType][sIcon as FontAwesomeIconKeys]}
      />
    ) : (
      <img src={oIconLibrary[sIconType][sIcon as WfuIconKeys]} alt='' />
    )}
  </button>
);

export default IconOnlyButton;

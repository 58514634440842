import { useRef, useEffect } from 'react';
import { AxiosResponse } from 'axios';

import wnApi from 'utils/WnApi';

const usePollForPdf = () => {
  const pollingIntervalIdRef = useRef<number | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const stopPolling = () => {
    if (pollingIntervalIdRef.current) {
      console.log('Stopping polling for tax statement pdf.');
      clearInterval(pollingIntervalIdRef.current);
      pollingIntervalIdRef.current = null;
    }
    abortControllerRef.current?.abort();
    abortControllerRef.current = null;
  };

  const pollForPdfUrl = async (path: string) => {
    const pollingPath: string = `${path}&isPolling=true`;
    let attempts: number = 0;
    const maxAttempts: number = 18; // 90 seconds max (5 seconds * 18 attempts)
    const interval: number = 5000; // 5 seconds

    console.log('Starting polling for tax statement pdf.');
    const pollPromise: Promise<string> = new Promise((resolve, reject) => {
      const intervalId = setInterval(async () => {
        attempts++;
        try {
          console.log(`Polling for tax statement pdf: Attempt ${attempts}`);
          abortControllerRef.current = new AbortController();
          const response: AxiosResponse = await wnApi.get<AxiosResponse>(
            pollingPath
          );
          abortControllerRef.current = null;

          switch (response.status) {
            case 200: // woot, we got the pdf
              if (response.data.pdfUrl) {
                resolve(response.data.pdfUrl);
                stopPolling();
              } else {
                throw new Error(
                  'Something went wrong while polling for the tax statement. No pdfUrl in response data.'
                );
              }
              break;
            case 404: // not ready yet, keep polling
              break;
            default:
              throw new Error(
                `Something went wrong while polling for the tax statement. ${response.status}:${response.statusText}; ${response.data}`
              );
          }

          if (attempts >= maxAttempts) {
            throw new Error(
              `Exceeded max polling attempts (${maxAttempts}). Giving up.`
            );
          }
        } catch (error: any) {
          if (error.name === 'AbortError') {
            console.log('Request was aborted.');
          } else {
            console.error(error);
            reject(error);
            stopPolling();
          }
        }
      }, interval);
      pollingIntervalIdRef.current = intervalId as unknown as number;
    });
    return pollPromise;
  };

  // Stop polling when component unmounts
  useEffect(
    () => () => {
      stopPolling();
    },
    []
  );

  return { pollForPdfUrl, stopPolling };
};

export default usePollForPdf;

/**
 * Retrieves current user's lookup ID from the Redux store.
 *
 * @param {object} state - Redux store.
 * @returns {string}
 */
const currentUserLookupIdSelector = (state) =>
  ((state.Profile || {}).BIOGRAPHICAL || {}).LOOKUPID || '';

/**
 * Retrieves current user's first and last name from the Redux store.
 *
 * @param {object} state - Redux store.
 * @returns {object}
 */
const currentUserFirstLastNameSelector = (state) => {
  const sFirstName = ((state.Profile || {}).BIOGRAPHICAL || {}).FIRSTNAME || '';
  const sPreferredName =
    state.Profile?.BIOGRAPHICAL?.NICKNAME || sFirstName || '';
  const sLastName = ((state.Profile || {}).BIOGRAPHICAL || {}).LASTNAME || '';
  const sFullName = state?.Profile?.BIOGRAPHICAL?.NAME || '';
  return {
    sFirstName,
    sPreferredName,
    sLastName,
    sName: sFullName,
  };
};

/**
 * Retrieves current user's nickname from the Redux store.
 *
 * @param {object} state - Redux store.
 * @returns {string}
 */
const currentUserNicknameSelector = (state) =>
  state.Profile?.BIOGRAPHICAL?.NICKNAME || '';

/**
 * Retrieves the current user's primary email address on record.
 * If no primary email address is found, the first email address is returned.
 *
 * @param {object} state - Redux store.
 * @returns {string} - current user's email address
 */
export const selectCurrentUserEmail = (state) => {
  let sEmail = '';
  const aAllEmails = state.Profile?.EMAIL || [];
  const oPrimaryEmail = aAllEmails.find((oEmail) => oEmail.PRIMARY);
  if (oPrimaryEmail) {
    sEmail = oPrimaryEmail.EMAILADDRESS;
  } else if (aAllEmails.length > 0) {
    sEmail = aAllEmails[0].EMAILADDRESS;
  }
  return sEmail;
};

export {
  currentUserLookupIdSelector,
  currentUserFirstLastNameSelector,
  currentUserNicknameSelector,
};

import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import Modal from 'components/Layout/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import {
  fnAddEventToCart,
  fnAddWaitlistEventToCart,
} from 'components/EventRegistration/redux/eventHelpers';
import { fnIsEventAtCapacity } from 'components/Events/helpers';

const SignInOrContinueAsGuestModal = ({
  fnSetShowModal,
  fnSetShowSignInModal,
  aChosenEvents,
}) => {
  const fnNavigate = useNavigate();

  const fnStartEventRegistrationAsGuest = () => {
    aChosenEvents.forEach((oEvent) => {
      const bIsAtCapacity = fnIsEventAtCapacity(oEvent);
      const bIsWaitlistEvent = [true, 1].includes(
        oEvent?.REGISTRATION?.ALLOW_WAITLIST
      );
      if (bIsAtCapacity && bIsWaitlistEvent) {
        fnAddWaitlistEventToCart(oEvent);
      } else {
        fnAddEventToCart(oEvent);
      }
    });
    fnNavigate('/events/register/user-identification');
  };

  return (
    <Modal
      sModalTitle='Thanks for your interest in registering'
      sModalSubtitle='If you have a WakeNetwork account, please sign in for a more seamless registration experience.'
      sFlavor='sign-in'
      fnSetShowModal={fnSetShowModal}
    >
      <div
        className='eventDetailsPage__signInOrContinueAsGuestModalButtons'
        data-cy='sign-in-or-continue-as-guest-modal'
      >
        <Button
          fnHandleClick={() => {
            fnSetShowModal(false);
            fnSetShowSignInModal(true);
          }}
          sCypressId='sign-in-button'
        >
          Sign in
        </Button>
        <span className='t-label'>or</span>
        <Button
          fnHandleClick={fnStartEventRegistrationAsGuest}
          sFlavor='secondary'
          sCypressId='continue-as-guest-button'
        >
          Continue as Guest
        </Button>
      </div>
    </Modal>
  );
};

SignInOrContinueAsGuestModal.propTypes = {
  fnSetShowModal: PropTypes.func.isRequired,
  fnSetShowSignInModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  aChosenEvents: PropTypes.array.isRequired,
};

export default SignInOrContinueAsGuestModal;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import AddToCalendar from 'components/EventDetailsPage/AddToCalendar';
import { fnFormatSubEventDateAndTime } from 'components/EventDetailsPage/helpers';
import { oCurrencyFormatter } from 'utils/common';
import ComponentErrorBoundary from 'components/ErrorBoundaries/ComponentErrorBoundary';
import EventRegistrantName from '../EventRegistrantName';

const EventConfirmationCardContent = ({ aEvents }) => (
  <>
    {aEvents.map((oEventInCart) => {
      const bIsEventWaitlist = oEventInCart.sType === 'event-waitlist';
      const { oEvent, aGuests } = oEventInCart.oDetails;
      const {
        LOOKUPID,
        TITLE,
        SLUG,
        LOCATION,
        START_DATE_TIME,
        END_DATE_TIME,
        TIME_ZONE,
      } = oEvent;
      const bEventHasMultipleRegOptions =
        oEvent.REGISTRATION.REGISTRATION_OPTIONS.length > 1;
      return (
        <div key={oEventInCart.sId} data-cy={`event-${LOOKUPID}-details`}>
          <div className='eventConfirmation__eventHeader'>
            <div>
              <Link to={`/events/${SLUG}`} className='t-paragraph--large'>
                {TITLE}
              </Link>
              <p className='eventConfirmation__eventDateTime t-paragraph--small'>
                {fnFormatSubEventDateAndTime(
                  START_DATE_TIME,
                  END_DATE_TIME,
                  TIME_ZONE
                )}
              </p>
              <p className='eventConfirmation__eventLocation t-paragraph--small'>
                {LOCATION.NAME}
              </p>
            </div>
            <ComponentErrorBoundary sErrorMessage='Something went wrong with the Add to Calendar button'>
              <AddToCalendar oEvent={oEvent} />
            </ComponentErrorBoundary>
          </div>
          <table
            className='eventConfirmation__guestTable'
            data-cy={`event-${LOOKUPID}-registrants-table`}
          >
            <thead>
              <tr>
                <th>Name</th>
                {bEventHasMultipleRegOptions && !bIsEventWaitlist && (
                  <th>Option</th>
                )}
                {!bIsEventWaitlist && (
                  <th className='eventConfirmation__eventOptionCostColumn'>
                    Cost
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {aGuests.map((oGuest) => (
                <tr
                  key={oGuest.LOOKUPID}
                  data-cy={`registrant-${oGuest.LOOKUPID}-row`}
                >
                  <td data-cy='registrant-name'>
                    <EventRegistrantName sName={oGuest.NAME} />
                  </td>
                  {bEventHasMultipleRegOptions && !bIsEventWaitlist && (
                    <td data-cy='registrant-option-name'>
                      {oGuest.REG_OPTION.NAME}
                    </td>
                  )}
                  {!bIsEventWaitlist && (
                    <td
                      className='eventConfirmation__eventOptionCostColumn'
                      data-cy='registrant-cost'
                    >
                      {oCurrencyFormatter.format(oGuest.REG_OPTION.COST)}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    })}
  </>
);

export default EventConfirmationCardContent;

EventConfirmationCardContent.propTypes = {
  aEvents: PropTypes.arrayOf(
    PropTypes.shape({
      oDetails: PropTypes.shape({
        oEvent: PropTypes.shape({
          TITLE: PropTypes.string,
          SLUG: PropTypes.string,
          LOCATION: PropTypes.shape({
            NAME: PropTypes.string,
          }),
          START_DATE_TIME: PropTypes.string,
          END_DATE_TIME: PropTypes.string,
          TIME_ZONE: PropTypes.string,
          REGISTRATION: PropTypes.shape({
            REGISTRATION_OPTIONS: PropTypes.arrayOf(
              PropTypes.shape({
                NAME: PropTypes.string,
                COST: PropTypes.number,
              })
            ),
          }),
        }),
        aGuests: PropTypes.arrayOf(
          PropTypes.shape({
            LOOKUPID: PropTypes.string,
            NAME: PropTypes.string,
            REG_OPTION: PropTypes.shape({
              NAME: PropTypes.string,
              COST: PropTypes.number,
            }),
          })
        ),
      }),
      sId: PropTypes.string,
    })
  ).isRequired,
};

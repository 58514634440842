import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import oIconLibrary from 'utils/iconLibrary';

import {
  fnAddEventToCart,
  fnAddWaitlistEventToCart,
} from 'components/EventRegistration/redux/eventHelpers';
import { fnAddGuestToEventInCart } from 'components/EventRegistration/redux/guestHelpers';
import {
  currentUserFirstLastNameSelector,
  currentUserLookupIdSelector,
  selectCurrentUserEmail,
} from 'redux/selectors';
import { selectEventRegNavSteps } from 'components/EventRegistration/EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';
import Accordion from 'components/Layout/Accordion';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import { fnIsEventAtCapacity } from 'components/Events/helpers';
import EventDescription from '../EventDescription';
import SubEventDetailCard from './SubEventDetailCard';
import SelectedEventsList from './SelectedEventsList';
import { oInitialValues, oSchema } from './helpers';
import EventDetailsPageHeader from '../EventDetailsPageHeader';
import MultiLevelEventDetailsFooter from './MultiLevelEventDetailsFooter';
import EventRegistrationWindowClose from '../EventRegistrationWindowClose';

const MultiLevelEventDetails = ({
  oEvent,
  fnSetShowSignInOrContinueAsGuestModal,
  fnSetChosenEvents,
}) => {
  const fnNavigate = useNavigate();
  const fnDispatch = useDispatch();
  const [bHasSubmittedForm, fnSetHasSubmittedForm] = useState(false);
  const sCurrentUserLookupId = useSelector(currentUserLookupIdSelector);
  const oCurrentUserName = useSelector(currentUserFirstLastNameSelector);
  const sCurrentUserEmail = useSelector(selectCurrentUserEmail);
  const [sFirstEventRegStep] = useSelector(selectEventRegNavSteps);
  const bIsAuthenticated = useIsUserAuthenticated();
  const bHasSubEvents = oEvent?.SUB_EVENTS?.length > 0;

  const fnHandleContinue = (oFormValues) => {
    fnDispatch(
      CheckoutActions.update({
        fullName: oCurrentUserName.sName,
        firstName: oCurrentUserName.sPreferredName,
        lastName: oCurrentUserName.sLastName,
        email: sCurrentUserEmail,
        userLookupId: sCurrentUserLookupId,
      })
    );

    // Add the selected sub events to the cart
    oFormValues.subEvents.forEach((sSubEvent) => {
      const oSubEvent = JSON.parse(sSubEvent);
      const bIsAtCapacity = fnIsEventAtCapacity(oSubEvent);
      const bIsWaitlistEvent = [true, 1].includes(
        oSubEvent?.REGISTRATION?.ALLOW_WAITLIST
      );
      if (bIsAtCapacity && bIsWaitlistEvent) {
        fnAddWaitlistEventToCart(oSubEvent);
      } else {
        fnAddEventToCart(oSubEvent);
      }
      if (bIsAuthenticated) {
        fnAddGuestToEventInCart(
          `${oCurrentUserName.sPreferredName} ${oCurrentUserName.sLastName}`,
          sCurrentUserLookupId,
          oSubEvent.LOOKUPID
        );
      }
    });

    fnSetHasSubmittedForm(true);
  };

  // Navigate to the first step in the event registration flow
  // Doing this in a useEffect instead of in the submit handler allows the
  // `selectEventRegNavSteps` selector to re-calculate against the new event(s) in the cart.
  useEffect(() => {
    if (bHasSubmittedForm && sFirstEventRegStep) {
      fnNavigate(sFirstEventRegStep);
    }
  }, [bHasSubmittedForm, sFirstEventRegStep, fnNavigate]);

  return (
    <>
      <EventDetailsPageHeader oEvent={oEvent} />
      <div>
        {oEvent?.DESCRIPTION && (
          <EventDescription
            sDescription={oEvent.DESCRIPTION}
            bIncludeHeader={false}
          />
        )}
        <EventRegistrationWindowClose
          registrationCloseDateTime={oEvent?.REGISTRATION?.CLOSE_DATETIME}
          timeZone={oEvent?.TIME_ZONE}
        />
        {bHasSubEvents ? (
          <div className='multiLevelEventDetails__subEventsWrapper'>
            <h3 className='t-header4'>
              <FontAwesomeIcon icon={oIconLibrary.fontAwesome.mapWithMarker} />{' '}
              Events
            </h3>

            <Formik
              initialValues={oInitialValues}
              onSubmit={fnHandleContinue}
              validationSchema={oSchema}
            >
              {(formikProps) => (
                <Form>
                  <>
                    {/* @todo: remove this Homecoming 2024 lookup id check once Homecoming moves to WN registration. */}
                    {oEvent.LOOKUPID !== '8-10010554' && (
                      <p>Select the events you would like to attend.</p>
                    )}
                    <ul className='multiLevelEventDetails__subEventsList'>
                      {oEvent.SUB_EVENTS.map((oSubEvent) => {
                        const bIsGrouping =
                          (oSubEvent.SUB_EVENTS || []).length > 0;
                        if (bIsGrouping) {
                          return (
                            <Accordion
                              mTitle={oSubEvent.TITLE}
                              sTitleIcon='mapMarker'
                              className='multiLevelEventDetails__subEventsGrouping'
                              key={oSubEvent.ID}
                            >
                              {oSubEvent.SUB_EVENTS.map((oSubSubEvent) => (
                                <SubEventDetailCard
                                  key={oSubSubEvent.ID}
                                  oSubEvent={oSubSubEvent}
                                />
                              ))}
                            </Accordion>
                          );
                        }
                        return (
                          <SubEventDetailCard
                            key={oSubEvent.ID}
                            oSubEvent={oSubEvent}
                          />
                        );
                      })}
                    </ul>
                  </>

                  {formikProps.errors?.subEvents && (
                    <p className='form__overallError'>
                      {formikProps.errors?.subEvents}
                    </p>
                  )}
                  <SelectedEventsList />
                  <MultiLevelEventDetailsFooter
                    oEvent={oEvent}
                    fnSetShowSignInOrContinueAsGuestModal={
                      fnSetShowSignInOrContinueAsGuestModal
                    }
                    fnSetChosenEvents={fnSetChosenEvents}
                  />
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <div className='subEventDetailCard'>
            <p className='notInvited__contact'>
              <strong>{oEvent?.TITLE}</strong> does not have any events that you
              can register for at this time.
            </p>
            <p className='notInvited__contact'>
              If there&apos;s been a mistake, please contact{' '}
              {oEvent.CONTACT.NAME} at{' '}
              <a href={`mailto:${oEvent.CONTACT.EMAIL}`}>
                {oEvent.CONTACT.EMAIL}
              </a>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

MultiLevelEventDetails.propTypes = {
  oEvent: PropTypes.shape({
    TITLE: PropTypes.string.isRequired,
    CONTACT: PropTypes.shape({
      NAME: PropTypes.string.isRequired,
      EMAIL: PropTypes.string.isRequired,
    }).isRequired,
    DESCRIPTION: PropTypes.string,
    TIME_ZONE: PropTypes.string.isRequired,
    SUB_EVENTS: PropTypes.arrayOf(
      PropTypes.shape({
        ID: PropTypes.string.isRequired,
        TITLE: PropTypes.string.isRequired,
        REGISTRATION: PropTypes.shape({
          CLOSE_DATETIME: PropTypes.string.isRequired,
        }),
      })
    ),
  }).isRequired,
  fnSetShowSignInOrContinueAsGuestModal: PropTypes.func.isRequired,
  fnSetChosenEvents: PropTypes.func.isRequired,
};

export default MultiLevelEventDetails;

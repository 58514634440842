import * as R from 'ramda';

import { fnHighlightSearchResult } from 'components/Search/elements/QuickSearch/QuickSearchResultsList/helpers';

const fnNormalizeQuickSearchHits = (aHits) =>
  aHits.map((oHit) => ({
    LOOKUPID: oHit.id,
    NAME: oHit.fields.name[0],
    NICKNAME: oHit.fields.nickname?.[0],
    LAST_NAME: oHit.fields.last_name?.[0],
    HIGHLIGHT: fnHighlightSearchResult(oHit),
  }));

const fnNormalizeMyNetworkSearchResults = (aResults = []) =>
  aResults.map((oResult) => ({
    LOOKUPID: oResult.obj.LOOKUPID,
    NAME: oResult.obj.NAME,
    NICKNAME: oResult.obj.NICKNAME,
    LAST_NAME: oResult.obj.LASTNAME,
    HIGHLIGHT: oResult[0].highlight(),
  }));

/**
 * Combines quick search results and My Network search results into a single array.
 * Ordered like this: [My Network results, quick search results]
 *
 * @param {array} aQuickSearchHits - Quick search results
 * @param {array} aMyNetworkSearchResults - My Network search results
 * @returns {array}
 */
const fnCombineSearchResults = (aQuickSearchHits, aMyNetworkSearchResults) => {
  const aNormalizedQuickSearchResults =
    fnNormalizeQuickSearchHits(aQuickSearchHits);
  const aNormalizedMyNetworkSearchResults = fnNormalizeMyNetworkSearchResults(
    aMyNetworkSearchResults
  );

  const aCombinedResults = [
    ...aNormalizedMyNetworkSearchResults,
    ...aNormalizedQuickSearchResults,
  ];
  return R.uniqBy(R.prop('LOOKUPID'), aCombinedResults);
};

export default fnCombineSearchResults;

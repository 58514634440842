import React from 'react';

import IconOnlyButton, {
  IconOnlyButtonProps,
} from 'components/Buttons/IconOnlyButton';

type PencilEditButtonProps = Omit<IconOnlyButtonProps, 'sIcon'> & {
  bIsEditing?: boolean;
};

// Displays a button with a pencil icon that changes to an "X" icon when in edit mode.
const PencilEditButton: React.FC<PencilEditButtonProps> = ({
  bIsEditing = false,
  sAriaLabel = 'Toggle edit mode',
  ...oProps
}) => (
  <IconOnlyButton
    sIcon={bIsEditing ? 'close' : 'edit'}
    sAriaLabel={sAriaLabel}
    {...oProps}
  />
);

export default PencilEditButton;

import React from 'react';
import { Link } from 'react-router-dom';

import LoadingZone from 'components/LoadingZone';
import Accordion from '../../Layout/Accordion';
import GivingHistory from './GivingHistory';
import GivingSummary from './GivingSummary';
import GivingSkeleton from './GivingSkeleton';
import Pledges from './Pledges';
import usePhilanthropyData from './usePhilanthropyData';
import TaxStatements from './TaxStatements';

const MyGiving = () => {
  const { bIsLoading, oPhilanthropyData } = usePhilanthropyData();
  const bHasPledges = oPhilanthropyData?.PLEDGES?.length > 0;
  const bHasHistory = oPhilanthropyData?.GIVING?.HISTORY_BY_YEAR?.length > 0;
  const bShowTaxStatements =
    !!oPhilanthropyData?.GIVING?.SUMMARY?.TAX_YEARS_WITH_ATHLETIC_GIVING;

  return (
    <Accordion
      sTitleIcon='bookClosed'
      mTitle='My Giving'
      bStartOpen
      sGtmCategory='My Giving'
    >
      <div className='myGiving__makeAGiftWrapper'>
        <Link className='myGiving__makeAGift button' to='/philanthropy/giving'>
          Make a gift
        </Link>
      </div>
      <LoadingZone
        isLoading={bIsLoading}
        skeleton={<GivingSkeleton />}
        message='Loading my giving'
      >
        <>
          <GivingSummary data={oPhilanthropyData?.GIVING?.SUMMARY} />
          {bHasPledges && <Pledges aPledgeData={oPhilanthropyData.PLEDGES} />}
          {bHasHistory && (
            <GivingHistory data={oPhilanthropyData?.GIVING?.HISTORY_BY_YEAR} />
          )}
          {bShowTaxStatements && (
            <TaxStatements
              taxYears={
                oPhilanthropyData?.GIVING?.SUMMARY
                  ?.TAX_YEARS_WITH_ATHLETIC_GIVING
              }
            />
          )}
        </>
      </LoadingZone>
    </Accordion>
  );
};

export default MyGiving;

import { TaxYear } from './types';

export const buildTaxYearOptions = (taxYears: TaxYear[] = []) => {
  const options = taxYears.map((taxYear: TaxYear) => ({
    label: taxYear.YEAR,
    value: taxYear.YEAR,
  }));
  return options;
};

export const buildTaxStatementFormInitialValues = (
  taxYears: TaxYear[] = []
) => {
  const intialValues = {
    year: taxYears.length > 0 ? taxYears[0].YEAR : '',
  };
  return intialValues;
};

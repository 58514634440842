import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';

// Displays a "please wait" message if it takes more than 5 seconds to generate a tax statement
const TaxStatementPleaseHoldMsg = () => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const { isSubmitting } = useFormikContext();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isSubmitting) {
      timer = setTimeout(() => {
        setShowMessage(true);
      }, 5000);
    } else {
      setShowMessage(false);
    }

    return () => clearTimeout(timer);
  }, [isSubmitting]);

  return showMessage ? (
    <p className='taxStatements__pleaseHold t-paragraph--small'>
      Please wait; it may take a minute or two to generate your tax statement.
    </p>
  ) : null;
};

export default TaxStatementPleaseHoldMsg;

import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';

import { Select } from 'components/Form/Fields';
import { SubmitButton } from 'components/Form/Buttons';
import {
  buildTaxStatementFormInitialValues,
  buildTaxYearOptions,
} from './helpers';
import { TaxYear } from './types';
import TaxStatementPleaseHoldMsg from './TaxStatementPleaseHoldMsg';

type TaxStatementFormProps = {
  taxYears?: TaxYear[];
  handleSubmit: (formValues: { year: string }) => void;
};

const TaxStatementForm: React.FC<TaxStatementFormProps> = ({
  taxYears = [],
  handleSubmit,
}) => {
  const options = useMemo(() => buildTaxYearOptions(taxYears), [taxYears]);
  const initialValues = useMemo(
    () => buildTaxStatementFormInitialValues(taxYears),
    [taxYears]
  );
  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {(formikProps) => (
        <Form>
          <Select
            label='Year'
            id='tax-year'
            name='year'
            valueKey='value'
            options={options}
            className='taxStatements__select'
          />
          <SubmitButton bIsCentered>Generate PDF Link</SubmitButton>
          <TaxStatementPleaseHoldMsg />
        </Form>
      )}
    </Formik>
  );
};

export default TaxStatementForm;

import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import AddConstituentForm from '../AddConstituentForm';

const CreateEventRegistrationUser = ({
  fnSetNewUser,
  oNewUserInitialValues,
  fnSetUIPhase,
}) => (
  <div>
    <h1 className='guestManagement__addFormTitle t-paragraph--medium'>
      Tell us more about you.
    </h1>
    <AddConstituentForm
      oInitialValues={oNewUserInitialValues}
      fnHandleSubmit={(oFormValues) => {
        fnSetNewUser({
          FIRST_NAME: oFormValues.firstName,
          LAST_NAME: oFormValues.lastName,
          EMAIL_ADDRESS: oFormValues.email,
        });
        fnSetUIPhase('');
      }}
      sSubmitButtonLabel='Save'
    />
    <Button
      sFlavor='underline'
      bIsCentered
      fnHandleClick={() => fnSetUIPhase('search')}
      className='guestManagement__cancelButton'
    >
      Cancel
    </Button>
  </div>
);

CreateEventRegistrationUser.propTypes = {
  fnSetNewUser: PropTypes.func.isRequired,
  oNewUserInitialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  fnSetUIPhase: PropTypes.func.isRequired,
};

export default CreateEventRegistrationUser;

import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import ConstituentSearch from '../ConstituentSearch';
import { fnBuildFormikInitialNameValues } from '../ConstituentSearch/helpers';

const EventRegistrationUserSearchFooter = ({
  sQuery,
  fnSetUIPhase,
  fnSetNewPersonInitialValues,
}) => (
  <Button
    sFlavor='underline'
    fnHandleClick={() => {
      fnSetNewPersonInitialValues({
        ...fnBuildFormikInitialNameValues(sQuery),
        email: '',
      });
      fnSetUIPhase('create');
    }}
    className='eventRegistrationUserSearchFooter__addManuallyButton'
  >
    None of these are me
  </Button>
);

EventRegistrationUserSearchFooter.propTypes = {
  sQuery: PropTypes.string.isRequired,
  fnSetUIPhase: PropTypes.func.isRequired,
  fnSetNewPersonInitialValues: PropTypes.func.isRequired,
};

const EventRegistrationUserSearch = ({
  fnSetUIPhase,
  fnSetNewUser,
  fnSetNewUserInitialValues,
  className,
}) => (
  <div className={className}>
    <h1 className='guestManagment__searchInstructions t-paragraph--medium'>
      Let&apos;s see if we can find you in our system.
    </h1>
    <ConstituentSearch
      fnSetGuestUIPhase={fnSetUIPhase}
      fnSetNewGuestInitialValues={fnSetNewUserInitialValues}
      fnHandleNewConstituent={(oName, sLookupId) => {
        fnSetNewUser({
          FIRST_NAME: oName.FIRST_NAME,
          LAST_NAME: oName.LAST_NAME,
          LOOKUPID: sLookupId,
        });
        fnSetUIPhase('');
      }}
      sLabel='Enter your first and last name'
      AlternateFooterComponent={EventRegistrationUserSearchFooter}
    />
  </div>
);
EventRegistrationUserSearch.propTypes = {
  fnSetUIPhase: PropTypes.func.isRequired,
  fnSetNewUser: PropTypes.func.isRequired,
  fnSetNewUserInitialValues: PropTypes.func.isRequired,
  className: PropTypes.string,
};

EventRegistrationUserSearch.defaultProps = {
  className: '',
};

export default EventRegistrationUserSearch;

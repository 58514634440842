import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import Modal from 'components/Layout/Modal/Modal';
import { selectAllEventsInCart } from 'components/EventRegistration/redux/selectors';

const SignInToChangeEmailModal = ({ fnSetShowModal, fnSetShowSignInModal }) => {
  const oEventContact = useSelector(selectAllEventsInCart)[0].oDetails.oEvent
    .CONTACT;
  return (
    <Modal
      fnSetShowModal={fnSetShowModal}
      sModalTitle='Please sign in to update or add your email'
      sModalSubtitle='Keeping your data safe is important to us. Please sign in to update or add your email address or contact us for help.'
      sFlavor='sign-in'
    >
      <div className='signInToChangeEmailModal__buttons'>
        <Button
          fnHandleClick={() => {
            fnSetShowModal(false);
            fnSetShowSignInModal(true);
          }}
        >
          Sign in
        </Button>
        <span className='t-label'>or</span>
        <p>
          Contact {oEventContact.NAME} at{' '}
          <a href={`mailto:${oEventContact.EMAIL}`}>{oEventContact.EMAIL}</a>
        </p>
      </div>
    </Modal>
  );
};

SignInToChangeEmailModal.propTypes = {
  fnSetShowModal: PropTypes.func.isRequired,
  fnSetShowSignInModal: PropTypes.func.isRequired,
};

export default SignInToChangeEmailModal;

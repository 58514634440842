import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button from 'components/Buttons/Button';
import LoadingZone from 'components/LoadingZone';
import oWnApi from 'utils/WnApi';
import Modal from 'components/Layout/Modal/Modal';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import EventRegistrationUserSearch from 'components/EventRegistration/EventRegistrationUserIdentification/EventRegistrationUserSearch';
import {
  currentUserFirstLastNameSelector,
  currentUserLookupIdSelector,
} from 'redux/selectors';

const ConfirmRsvpNoModal = ({
  sEventTitle,
  sEventLookupId,
  fnSetShowRsvpNoConfirmModal,
  fnSetHasRsvpdNo,
}) => {
  const { eventSlug } = useParams();
  const bIsUserAuthenticated = useIsUserAuthenticated();
  const [bIsProcessingRsvpNo, fnSetIsProcessingRsvpNo] = useState(false);
  const [sRsvpPhase, fnSetRsvpPhase] = useState(''); // ['identifyUser', 'confirm', 'success', 'error']
  const [sUserLookupId, fnSetUserLookupId] = useState('');
  const [sCurrentUserName, fnSetCurrentUserName] = useState('');
  const sAuthenticatedUserLookupId = useSelector(currentUserLookupIdSelector);
  const oAuthenticatedUserName = useSelector(currentUserFirstLastNameSelector);

  // Determine the starting UI phase based on if the user is authenticated
  useEffect(() => {
    switch (bIsUserAuthenticated) {
      case true:
        fnSetRsvpPhase('confirm');
        break;
      case false:
        fnSetRsvpPhase('identifyUser');
        break;
      default:
        fnSetRsvpPhase('');
        break;
    }
  }, [bIsUserAuthenticated]);

  useEffect(() => {
    if (sAuthenticatedUserLookupId) {
      fnSetUserLookupId(sAuthenticatedUserLookupId);
      fnSetCurrentUserName(oAuthenticatedUserName.sName);
    }
  }, [sAuthenticatedUserLookupId, oAuthenticatedUserName]);

  const fnRsvpNo = async () => {
    fnSetIsProcessingRsvpNo(true);
    const oResponse = await oWnApi.post(
      `events/${eventSlug}/rsvp`,
      {
        USER_LOOKUPID: sUserLookupId,
        EVENT_LOOKUPID: sEventLookupId,
        RSVP_RESPONSE: 0,
      },
      bIsUserAuthenticated
    );
    if (oResponse.status === 200) {
      fnSetRsvpPhase('success');
      fnSetHasRsvpdNo(true);
    } else {
      console.error('Failed to RSVP No for event');
      fnSetRsvpPhase('error');
    }
    fnSetIsProcessingRsvpNo(false);
  };

  const oModalMessages = {
    identifyUser: 'Identify yourself',
    confirm: bIsUserAuthenticated
      ? `Are you sure you want to RSVP No for ${sEventTitle}?`
      : `${sCurrentUserName}, are you sure you want to RSVP No for ${sEventTitle}?`,
    success: `You have successfully RSVP'd No for ${sEventTitle}.`,
    error: 'Something went wrong. Please try again.',
  };

  return (
    <Modal
      sFlavor='message'
      sModalSubtitle={oModalMessages[sRsvpPhase]}
      fnSetShowModal={fnSetShowRsvpNoConfirmModal}
      sCypressId='rsvp-no-modal'
    >
      {sRsvpPhase === 'identifyUser' && (
        <EventRegistrationUserSearch
          fnSetUIPhase={() => {}}
          fnSetNewUser={(oChosenUser) => {
            fnSetUserLookupId(oChosenUser.sLookupId);
            fnSetCurrentUserName(oChosenUser.sName);
            fnSetRsvpPhase('confirm');
          }}
          fnSetNewUserInitialValues={() => {}}
          className='confirmRsvpNoModal__unauthSearch'
        />
      )}
      {sRsvpPhase === 'confirm' && (
        <LoadingZone isLoading={bIsProcessingRsvpNo}>
          <div className='confirmRsvpNoModal__buttons'>
            <Button
              fnHandleClick={fnRsvpNo}
              sCypressId='rsvp-no-confirm-button'
            >
              RSVP No
            </Button>
            <Button
              fnHandleClick={() => fnSetShowRsvpNoConfirmModal(false)}
              sFlavor='secondary'
            >
              Cancel
            </Button>
          </div>
          {!bIsUserAuthenticated && (
            <Button
              fnHandleClick={() => {
                fnSetRsvpPhase('identifyUser');
                fnSetUserLookupId('');
                fnSetCurrentUserName('');
              }}
              sFlavor='underline'
              className='confirmRsvpNoModal__notYouButton'
            >
              Not {sCurrentUserName}?
            </Button>
          )}
        </LoadingZone>
      )}
      {sRsvpPhase === 'success' && (
        <Button
          fnHandleClick={() => fnSetShowRsvpNoConfirmModal(false)}
          sFlavor='secondary'
          sCypressId='rsvp-no-success-close-button'
        >
          Close
        </Button>
      )}
      {sRsvpPhase === 'error' && (
        <Button
          fnHandleClick={() => fnSetShowRsvpNoConfirmModal(false)}
          sFlavor='secondary'
        >
          Close
        </Button>
      )}
    </Modal>
  );
};

ConfirmRsvpNoModal.propTypes = {
  sEventLookupId: PropTypes.string.isRequired,
  sEventTitle: PropTypes.string.isRequired,
  fnSetShowRsvpNoConfirmModal: PropTypes.func.isRequired,
  fnSetHasRsvpdNo: PropTypes.func.isRequired,
  oEventContact: PropTypes.shape({
    NAME: PropTypes.string,
    EMAIL: PropTypes.string,
  }).isRequired,
};

export default ConfirmRsvpNoModal;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';

const ConstituentSearchResultsList = ({
  aSearchResults,
  fnResetSearch,
  fnHandleNewConstituent,
}) => {
  if (!aSearchResults) {
    return null;
  }

  if (aSearchResults.length === 0) {
    return (
      <p className='constituentSearchResultsList__noResults'>
        No results found.
      </p>
    );
  }

  return (
    <ol className='constituentSearchResultsList'>
      {aSearchResults.map((oResult) => (
        <li
          className='constituentSearchResultsList__item'
          key={oResult.LOOKUPID}
          data-cy={`constituent-search-result-${oResult.LOOKUPID}`}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button
            type='button'
            className='constituentSearchResultsList__button'
            onClick={() => {
              const oName = {
                FIRST_NAME: oResult.NICKNAME,
                LAST_NAME: oResult.LAST_NAME,
              };
              fnHandleNewConstituent(oName, oResult.LOOKUPID);
              fnResetSearch();
            }}
          >
            <FontAwesomeIcon
              icon={oIconLibrary.fontAwesome.user}
              className='constituentSearchResultsList__icon'
            />
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: oResult.HIGHLIGHT,
              }}
            />
          </button>
        </li>
      ))}
    </ol>
  );
};

ConstituentSearchResultsList.propTypes = {
  aSearchResults: PropTypes.arrayOf(PropTypes.object),
  fnResetSearch: PropTypes.func.isRequired,
  fnHandleNewConstituent: PropTypes.func.isRequired,
};

ConstituentSearchResultsList.defaultProps = {
  aSearchResults: [],
};

export default ConstituentSearchResultsList;

import * as R from 'ramda';
import debounce from 'lodash.debounce';

import store from 'redux/store';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import { arrayCast } from 'utils/common';
import { lookupIdMatcher } from 'utils/matchers';
import { fnCalculateEventCost } from '../helpers';
import { selectAllEventsInCart, selectEventsInCart } from './selectors';

export const fnGetNextAvailableGuestLookupId = (aEventsInCart) => {
  let iHighestLookupId = 0;

  aEventsInCart.forEach((oEventCartItem) => {
    oEventCartItem.oDetails.aGuests.forEach((oGuest) => {
      const bIsDadLookupId = lookupIdMatcher.test(oGuest.LOOKUPID);
      if (!bIsDadLookupId) {
        const iLookupId = parseInt(oGuest.LOOKUPID);
        if (iLookupId > iHighestLookupId) {
          iHighestLookupId = iLookupId;
        }
      }
    });
  });

  return (iHighestLookupId + 1).toString();
};

const fnBuildGuest = (
  sGuestName,
  sGuestLookupId,
  oEventCartItem,
  oNewGuestData
) => {
  // Grab the name and lookup ID from Checkout instead of BIOGRAPHICAL to accommodate unauth users
  const { fullName, userLookupId } = store.getState().Checkout;
  const { DIETARY_RESTRICTIONS = [], EVENT_EXPERIENCE_SUPPORT = '' } =
    store.getState()?.Profile?.BIOGRAPHICAL || {};
  const bIsWaitlistEvent = oEventCartItem.sType === 'event-waitlist';
  const aEventsInCart = selectAllEventsInCart(store.getState());
  // Prep the guest data, in case of an unnamed guest
  const sRealGuestLookupId =
    sGuestLookupId || fnGetNextAvailableGuestLookupId(aEventsInCart);
  const sRealGuestName =
    sGuestName || `Guest ${sRealGuestLookupId} of ${fullName}`;

  let oGuest = {
    ...oNewGuestData,
    NAME: sRealGuestName,
    LOOKUPID: sRealGuestLookupId,
    REG_OPTION: null,
    ADDITIONAL_INFO: {
      DIETARY_RESTRICTIONS: [],
      OTHER_DIETARY_RESTRICTION: '',
      ADDITIONAL_INFO_CONTENT: '',
    },
  };

  // Prefill the registration option if there's only one
  const { REGISTRATION_OPTIONS } = oEventCartItem.oDetails.oEvent.REGISTRATION;
  if (REGISTRATION_OPTIONS.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    oGuest.REG_OPTION = REGISTRATION_OPTIONS[0];
  }

  // For a waitlist event, prefrill the registration option as waitlist to avoid form validation errors
  if (bIsWaitlistEvent) {
    oGuest.REG_OPTION = { NAME: 'Waitlist', COST: 0 };
  }

  // If the guest is the current user, prefill their dietary restrictions
  if (sRealGuestLookupId === userLookupId) {
    const aCurrentUserDietaryRestrictions = DIETARY_RESTRICTIONS
      ? arrayCast(DIETARY_RESTRICTIONS)
      : [];
    const sCurrentUserOtherDietaryRestriction =
      aCurrentUserDietaryRestrictions.find(
        (oCode) => oCode.DESCRIPTION === 'Other'
      )?.OTHER_DIETARY_RESTRICTION || '';
    const sCurrentUserAdditionalInfo = EVENT_EXPERIENCE_SUPPORT || '';

    oGuest = {
      ...oGuest,
      ADDITIONAL_INFO: {
        DIETARY_RESTRICTIONS: aCurrentUserDietaryRestrictions,
        OTHER_DIETARY_RESTRICTION: sCurrentUserOtherDietaryRestriction,
        ADDITIONAL_INFO_CONTENT: sCurrentUserAdditionalInfo,
      },
    };
  }

  return oGuest;
};

/**
 * Adds a guest to an event in the cart.
 *
 * @param {string} sGuestName - Guest name. Format: "Nickname Lastname"
 * @param {string} sGuestLookupId - Guest lookup ID
 * @param {string} sEventLookupId - Event lookup ID
 * @param {object} oNewGuestData - Additional guest data
 *
 * @returns {string} - The lookup ID of the added guest
 *
 * @throws {Error} - DUPLICATE_GUEST : If the guest already exists in the event
 */
export const fnAddGuestToEventInCart = (
  sGuestName,
  sGuestLookupId,
  sEventLookupId,
  oNewGuestData = null
) => {
  // Get the event cart item
  const oEventCartItem = store
    .getState()
    .Checkout.aCart.find((oItem) => oItem.sId === sEventLookupId);
  const oEventCartItemCopy = R.clone(oEventCartItem);

  const oNewGuest = fnBuildGuest(
    sGuestName,
    sGuestLookupId,
    oEventCartItemCopy,
    oNewGuestData
  );

  // Check if the guest exists in the event
  const bIsDuplicateGuest = oEventCartItem.oDetails.aGuests.some(
    (oExistingGuest) =>
      oExistingGuest.LOOKUPID === oNewGuest.LOOKUPID ||
      (oExistingGuest.NAME === oNewGuest.NAME &&
        oExistingGuest.EMAIL_ADDRESS === oNewGuest.EMAIL_ADDRESS)
  );
  if (bIsDuplicateGuest) {
    const sGuestDetail = oNewGuest.EMAIL_ADDRESS
      ? `(${oNewGuest.EMAIL_ADDRESS})`
      : `(lookup ID ${oNewGuest.LOOKUPID})`;
    const sEventTitle = oEventCartItemCopy.oDetails.oEvent.TITLE;
    console.error(
      `Guest ${oNewGuest.NAME} ${sGuestDetail} already exists in event ${sEventTitle}.`
    );
    throw new Error('DUPLICATE_GUEST');
  }

  oEventCartItemCopy.oDetails.aGuests.push(oNewGuest);

  // Recalculate the event cost
  const nEventCost = fnCalculateEventCost(oEventCartItemCopy);
  oEventCartItemCopy.nPrice = nEventCost;

  store.dispatch(CheckoutActions.cart.updateItem(oEventCartItemCopy));

  return oNewGuest.LOOKUPID;
};

export const fnRemoveGuestFromEventInCart = (
  sGuestLookupId,
  sEventLookupId
) => {
  // Get the event cart item
  const oEventCartItem = store
    .getState()
    .Checkout.aCart.find((oItem) => oItem.sId === sEventLookupId);
  const oEventCartItemCopy = R.clone(oEventCartItem);

  // Remove the guest
  oEventCartItemCopy.oDetails.aGuests =
    oEventCartItemCopy.oDetails.aGuests.filter(
      (oGuest) => oGuest.LOOKUPID !== sGuestLookupId
    );

  // Recalculate the event cost
  const nEventCost = fnCalculateEventCost(oEventCartItemCopy);
  oEventCartItemCopy.nPrice = nEventCost;

  store.dispatch(CheckoutActions.cart.updateItem(oEventCartItemCopy));
};

export const fnRemoveAllGuestsFromEventInCart = (sEventLookupId) => {
  // Get the event cart item
  const oEventCartItem = store
    .getState()
    .Checkout.aCart.find((oItem) => oItem.sId === sEventLookupId);
  const oEventCartItemCopy = R.clone(oEventCartItem);

  // Remove all guests
  oEventCartItemCopy.oDetails.aGuests = [];

  // Recalculate the event cost
  const nEventCost = fnCalculateEventCost(oEventCartItemCopy);
  oEventCartItemCopy.nPrice = nEventCost;

  store.dispatch(CheckoutActions.cart.updateItem(oEventCartItemCopy));
};

export const fnUpdateGuestRegistrationOption = (
  sGuestLookupId,
  sEventLookupId,
  oRegistrationOption
) => {
  // Get the event cart item
  const oEventCartItem = store
    .getState()
    .Checkout.aCart.find((oItem) => oItem.sId === sEventLookupId);
  const oEventCartItemCopy = R.clone(oEventCartItem);

  // Update the guest registration option
  const oGuestToUpdate = oEventCartItemCopy.oDetails.aGuests.find(
    (oGuest) => oGuest.LOOKUPID === sGuestLookupId
  );
  oGuestToUpdate.REG_OPTION = oRegistrationOption;

  // Recalculate the event cost
  const nEventCost = fnCalculateEventCost(oEventCartItemCopy);
  oEventCartItemCopy.nPrice = nEventCost;

  store.dispatch(CheckoutActions.cart.updateItem(oEventCartItemCopy));
};

export const fnUpdateGuestAdditionalInfo = (
  sGuestLookupId,
  aDietaryRestrictionCodes = [],
  sOtherDietaryRestriction = '',
  sAdditionalInfo = ''
) => {
  // Get all the events in the cart
  const aEventsInCart = selectAllEventsInCart(store.getState());
  const aEventsInCartCopy = R.clone(aEventsInCart);

  // Update the guest's additional info
  aEventsInCartCopy.forEach((oEventCartItem) => {
    const oGuestToUpdate = oEventCartItem.oDetails.aGuests.find(
      (oGuest) => oGuest.LOOKUPID === sGuestLookupId
    );
    if (oGuestToUpdate) {
      let {
        DIETARY_RESTRICTIONS,
        OTHER_DIETARY_RESTRICTION,
        ADDITIONAL_INFO_CONTENT,
      } = oGuestToUpdate.ADDITIONAL_INFO || {};

      if (!R.equals(DIETARY_RESTRICTIONS, aDietaryRestrictionCodes)) {
        DIETARY_RESTRICTIONS = aDietaryRestrictionCodes;
      }

      if (OTHER_DIETARY_RESTRICTION !== sOtherDietaryRestriction) {
        OTHER_DIETARY_RESTRICTION = sOtherDietaryRestriction;
      }

      if (ADDITIONAL_INFO_CONTENT !== sAdditionalInfo) {
        ADDITIONAL_INFO_CONTENT = sAdditionalInfo;
      }

      oGuestToUpdate.ADDITIONAL_INFO = {
        DIETARY_RESTRICTIONS,
        OTHER_DIETARY_RESTRICTION,
        ADDITIONAL_INFO_CONTENT,
      };

      store.dispatch(CheckoutActions.cart.updateItem(oEventCartItem));
    }
  });
};

export const fnUpdateGuestDietaryRestrictions = (
  sGuestLookupId,
  aDietaryRestrictionCodes
) => {
  // Get all the events in the cart
  const aEventsInCart = selectEventsInCart(store.getState());
  const aEventsInCartCopy = R.clone(aEventsInCart);

  // Update the guest's additional info
  aEventsInCartCopy.forEach((oEventCartItem) => {
    const oGuestToUpdate = oEventCartItem.oDetails.aGuests.find(
      (oGuest) => oGuest.LOOKUPID === sGuestLookupId
    );
    if (oGuestToUpdate) {
      let { DIETARY_RESTRICTIONS } = oGuestToUpdate.ADDITIONAL_INFO || {};

      if (!R.equals(DIETARY_RESTRICTIONS, aDietaryRestrictionCodes)) {
        DIETARY_RESTRICTIONS = aDietaryRestrictionCodes;

        oGuestToUpdate.ADDITIONAL_INFO = {
          ...oGuestToUpdate.ADDITIONAL_INFO,
          DIETARY_RESTRICTIONS,
        };

        store.dispatch(CheckoutActions.cart.updateItem(oEventCartItem));
      }
    }
  });
};

const fnUpdateGuestOtherDietaryRestriction = (
  sGuestLookupId,
  sOtherDietaryRestriction
) => {
  // Get all the events in the cart
  const aEventsInCart = selectEventsInCart(store.getState());
  const aEventsInCartCopy = R.clone(aEventsInCart);

  // Update the guest's additional info
  aEventsInCartCopy.forEach((oEventCartItem) => {
    const oGuestToUpdate = oEventCartItem.oDetails.aGuests.find(
      (oGuest) => oGuest.LOOKUPID === sGuestLookupId
    );
    if (oGuestToUpdate) {
      let { OTHER_DIETARY_RESTRICTION } = oGuestToUpdate.ADDITIONAL_INFO || {};

      if (OTHER_DIETARY_RESTRICTION !== sOtherDietaryRestriction) {
        OTHER_DIETARY_RESTRICTION = sOtherDietaryRestriction;

        oGuestToUpdate.ADDITIONAL_INFO = {
          ...oGuestToUpdate.ADDITIONAL_INFO,
          OTHER_DIETARY_RESTRICTION,
        };

        store.dispatch(CheckoutActions.cart.updateItem(oEventCartItem));
      }
    }
  });
};

export const fnDebouncedUpdateGuestOtherDietaryRestriction = debounce(
  fnUpdateGuestOtherDietaryRestriction,
  500
);

const fnUpdateGuestAdditionalInfoContent = (
  sGuestLookupId,
  sAdditionalInfo
) => {
  // Get all the events in the cart
  const aEventsInCart = selectEventsInCart(store.getState());
  const aEventsInCartCopy = R.clone(aEventsInCart);

  // Update the guest's additional info
  aEventsInCartCopy.forEach((oEventCartItem) => {
    const oGuestToUpdate = oEventCartItem.oDetails.aGuests.find(
      (oGuest) => oGuest.LOOKUPID === sGuestLookupId
    );
    if (oGuestToUpdate) {
      let { ADDITIONAL_INFO_CONTENT } = oGuestToUpdate.ADDITIONAL_INFO || {};

      if (ADDITIONAL_INFO_CONTENT !== sAdditionalInfo) {
        ADDITIONAL_INFO_CONTENT = sAdditionalInfo;

        oGuestToUpdate.ADDITIONAL_INFO = {
          ...oGuestToUpdate.ADDITIONAL_INFO,
          ADDITIONAL_INFO_CONTENT,
        };

        store.dispatch(CheckoutActions.cart.updateItem(oEventCartItem));
      }
    }
  });
};

export const fnDebouncedUpdateGuestAdditionalInfoContent = debounce(
  fnUpdateGuestAdditionalInfoContent,
  500
);
